import React, { useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import store from '~/store';
import { useLocalize } from '~/hooks';
import { HiSparkles } from 'react-icons/hi2';
import HeaderDropdown from '~/components/Header/HeaderDropdown';
import { usePricingPopup, subscriptionType } from '~/components/helper/store';
import { showPricing } from '~/components/helper/global';
import { FaUser } from 'react-icons/fa6';
import Settings from '../Header/HeaderSettings';
import { useChatContext } from '~/hooks/ChatContext';


export default function MobileNav({ setNavVisible, showThemeModal }) {
  const conversation = useRecoilValue(store.conversation);
  const { newConversation } = store.useConversation();
  const { title = 'New Chat' } = conversation || {};
  const localize = useLocalize();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const dropdownRef = useRef(null);
  const pState = usePricingPopup((state) => state);
  const proState = subscriptionType((state) => state)
  const { resetPoweredBy } = useChatContext();
  const messagesTree = useRecoilValue(store.messagesTree);


  const toggleDropdown = () => {
    if (isDropdownOpen) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    } else {
      setIsDropdownOpen(true);
      setTimeout(() => setIsDropdownVisible(true), 10);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
      setTimeout(() => setIsDropdownOpen(false), 200);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const clickHandler = () => {
    newConversation();
    if (messagesTree && messagesTree.length > 0) {
      resetPoweredBy();
    }
  };

  return (
    <div className="mobile-nav nav-menu fixed left-0 right-0 top-0 z-20 flex items-center bg-white dark:bg-[#1A1D22] pl-1 py-2 px-2 text-gray-200 sm:pl-3 md:hidden border-b border-gray-300/10 dark:border-b dark:border-gray-900/90">
      <button
        type="button"
        className="menu-btn ml-2 text-gray-500 dark:text-white -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900  dark:hover:text-white"
        onClick={() => setNavVisible((prev) => !prev)}
      >
        <span className="sr-only">{localize('com_nav_open_sidebar')}</span>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-12 w-12 menu-icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="3" y1="12" x2="19" y2="12" />
          <line x1="3" y1="6" x2="19" y2="6" />
          <line x1="3" y1="18" x2="19" y2="18" />
        </svg>
      </button>
      <h1 className="nav-title flex-1 text-center text-base font-normal">
        {/* {title || localize('com_ui_new_chat')} */}
      </h1>
      <div className="flex justify-center items-center space-x-4">
        <button
          type="button"
          className="new-chat-mobile-btn"
          aria-label="new chat"
          onClick={() => clickHandler()}
        >
          <div className="block dark:hidden">
            <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36" height="37" rx="4" fill="#2D9EE0" />
              <path d="M16.378 14.002H18.754V17.494H22.156V19.69H18.754V23.182H16.378V19.69H12.976V17.494H16.378V14.002Z" fill="#F5F5F5" />
            </svg>
          </div>

          <div className="hidden dark:block">
            <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36" height="37" rx="4" fill="#1F435B" />
              <path d="M16.378 14.002H18.754V17.494H22.156V19.69H18.754V23.182H16.378V19.69H12.976V17.494H16.378V14.002Z" fill="#F5F5F5" />
            </svg>
          </div>

        </button>
        {!proState.isPro && !proState.isProMax && (
          <button
            className="max-w-320:w-[60px] max-w-320:px-1 max-w-320:py-2 max-w-320:text-[12px] max-w-320:rounded-md md:w-[140px] animate-peakFromBottom text-md md:text-xl inline-block cursor-pointer rounded-lg bg-gradient-to-b from-yellow-top to-yellow-bottom px-3 py-[7px] text-white duration-200 ease-in hover:bg-[#F6B818]/80"
            onClick={() => {
              if (window.mixpanel) {
                window.mixpanel.track('Chatpro-App', {
                  'click-location': 'Go-Pro-Button',
                });
              }
              showPricing(() => {
                pState.setShowPricingPopup(true);
              });
            }}
            title="GO PRO"
            type="button"
          >
            PRO <HiSparkles className='inline' />
          </button>
        )}
        <button
          className="bg-[#015288] text-white text-xl font-bold rounded-full px-2 py-2 flex items-center justify-center mx-auto"
          style={{ width: '40px', height: '40px' }}
          onClick={() => toggleDropdown()}
        >
          {AIPRO_USER.email ? <FaUser size={20} role="img" aria-label="User" /> : 'G'}
        </button>
      </div>
      {isDropdownOpen && (
        <div ref={dropdownRef}>
          <HeaderDropdown isVisible={isDropdownVisible}
            setIsDropdownVisible={setIsDropdownVisible}
            setShowSettings={setShowSettings}
            showThemeModal={showThemeModal} />
        </div>
      )}
      {showSettings && <Settings open={showSettings} onOpenChange={setShowSettings} />}
    </div>
  );
}
