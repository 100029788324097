import { cookie } from '~/utils/';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const showPricing = (cb) => {

  if (
    cookie.getValue('flow') === 'chatapp' &&
    cookie.getValue('n_flow') === 'chatapp' &&
    !cookie.getValue('user_plan')
  ) {
    const isStaging = window.location.href.includes('staging');
    if (isStaging) {
      window.location.href = 'https://staging.chatbotpro.org/pricing-redirect/?from=chatapp';
      return;
    }
    window.location.href = 'https://chatbotpro.org/pricing-redirect/?from=chatapp';
    return;
  }

  let r_flow = false;
  switch(cookie.getValue("r_flow")) {
    case 'app': //this is chatpro in chatbotpro.org
    case 'dreamphoto':
    case 'chatpdf':
      r_flow = true;
      break;
  }
  if(r_flow) {
    let wp_flow = cookie.getValue("flow");
    let register_logic_url = "/register";
    let wpBaseURL = "https://chatbotpro.org";
    switch (wp_flow) {
      case '02':
        register_logic_url = '/register-b';
        break;
      case '03':
        register_logic_url = '/register-x';
        break;
      case '04':
        register_logic_url = '/select-account-type-d'; //slash
        break;
      case '05':
        register_logic_url = '/aurora-register'; //slash
        break;
      default:
        register_logic_url = register_logic_url;
    }
    window.location.href = wpBaseURL + register_logic_url;
    return;
  }
  cb();
};

// export const setCookieValue = (key, value, domain) => {
//   cookie.setValue(key, value)
// };

export const setCookieValue = (key, value, domain) => {
  const options = {
    path: '/',
    domain: domain, // Ensure the domain is correctly set
    secure: true, // Use 'secure' if your site is served over HTTPS
    sameSite: 'Lax' // Or 'None' if cross-site requests need it
  };
  
  cookie.setValue(key, value, options);
};

export const setFlag = (paramName, domain) => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramValue = params.get(paramName);

    if (paramValue) {
      setCookieValue(paramName, paramValue, domain); 
      params.delete(paramName);
      window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
    }
  }, [location, paramName, domain]);
};


