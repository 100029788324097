import React, { useState, useEffect } from 'react';
import store from '~/store';
import { useChatContext } from '~/hooks/ChatContext';
import { useRecoilValue } from 'recoil';

const NewChat = ({ onAfterClick }) => {
  const { newConversation } = store.useConversation();
  const { resetPoweredBy } = useChatContext();
  const messagesTree = useRecoilValue(store.messagesTree);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const clickHandler = () => {
    newConversation();
    if (messagesTree && messagesTree.length > 0) {
      resetPoweredBy();
    }
    if (isMobile) {
      setTimeout(() => onAfterClick(), 200);
    }
  };

  return (
    <div
      onClick={clickHandler}
      className="new-chat-btn mb-4 flex flex-shrink-0 cursor-pointer items-center justify-center gap-1 rounded-md px-3 py-3 pr-5 text-sm font-semibold transition-colors duration-200"
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler();
        }
      }}
      aria-label="New chat"
    >
      + New Chat
    </div>
  );
};

export default NewChat;
